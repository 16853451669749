
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
  name: "widget-2",
  props: {
    widgetClasses: String,
  },
  components: {
    Dropdown2,
  },
});
